//
// overlay.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides
//

/*Dark overlay*/

// Bg Dark overlay
// Creates the "bg-dark-overlay-#" variant
@for $i from 2 through 9 {
  $bg-overlay-dark: ($i);
  .bg-overlay-dark-#{($i)} {
    position: relative;
    z-index: 1;
    &:before {
      content: " ";
      background: $black;
      opacity: ($i * .1);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

.parallax-bg:before{
  z-index:0;
}

/*pattern overlay*/
.pattern-overlay-1 {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    background: url(../images/bg/pattern/01.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}
.pattern-overlay-1-dark {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    background: url(../images/bg/pattern/01-dark.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}
.pattern-overlay-2 {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    background: url(../images/bg/pattern/02.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}
.pattern-overlay-3 {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    background: url(../images/bg/pattern/03.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}
.pattern-overlay-4 {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    background: url(../images/bg/pattern/04.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}