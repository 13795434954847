/*===============================
           HEADER  
=================================*/

/*TOP BAR*/
.navbar-top {
  border-bottom: 1px solid $border-color;
  padding: .2rem 0;
  span{
    font-size: $font-size-xs;
    color: $gray-600;
  }
  a {
    color: $gray-600;
    font-size: $font-size-xs;
    &:hover {
      color: $primary;
    }
  }
}

header {
  position: relative;
  z-index: 99;
}
/*header transparent*/
.navbar-transparent {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

// Navbar on dark bg
.navbar-dark .navbar-top{
  span{
    color: $white;
  }
  a {
    color: $white;
    &:hover {
      color: $primary;
    }
  }
}
.navbar-dark.navbar-sticky-on{
  background-color: $gray-800;
}

@include media-breakpoint-down(lg) {
  .navbar-dark .navbar-collapse .nav-link{
  color: $gray-600;
  }
}

// Navbar on primary bg
.navbar-primary .navbar-top{
  span{
    color: $white;
  }
  a {
    color: $white;
    &:hover {
      color: $gray-800;
    }
  }
}
.navbar-primary.navbar-sticky-on{
  background-color: $primary;
}

@include media-breakpoint-down(md) {
  .navbar-primary .navbar-collapse .nav-link{
  color: $gray-600;
  }
}

// header floating
.navbar-floating{
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  .navbar > .container{
    background: $white;
    border-radius: $border-radius;
  }
}
@include media-breakpoint-down(md) {
  .navbar-floating .navbar > .container{
    padding: 0 15px !important;
  }
  .navbar-sticky-on.navbar-floating .navbar > .container{
    padding: 0 !important;
  }
}


/*Header sticky*/
header.navbar-sticky-on {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  animation: fadeInDown .5s;
  width: 100%;
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.2);
  .navbar-top {
    display: none !important;
  }
  .navbar-nav .nav-link {
    line-height: $sticky-nav-link-line-height;
  }
  .navbar-brand {
    padding-top: $sticky-navbar-brand-padding-y;
    padding-bottom: $sticky-navbar-brand-padding-y;
    .navbar-brand-item{
      height: $sticky-navbar-brand-height;
    }
  }
}



@include media-breakpoint-down(md) {
 .navbar-nav .nav-link {
    line-height: $mobile-nav-link-line-height;
  }

  .navbar-brand {
    padding-top: $mobile-navbar-brand-padding-y;
    padding-bottom: $mobile-navbar-brand-padding-y;
    .navbar-brand-item{
      height: $mobile-navbar-brand-height;
    }
  }

  header .search i{
    vertical-align: middle;
  }
  header.navbar-sticky-on .navbar-nav .nav-link {
    line-height: 40px;
  }

}


/*megamenu dropdown menu full width in container fluid*/
.navbar .container-fluid .megamenu .dropdown-menu{
  width: 100%;
}

/*===============================
     HAMBURGER MENU(Home 10)
=================================*/
.hamburger-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .hamburger-inner {
    position: absolute;
    right: -100px;
    top: -115px;
    z-index: 100;
    width: 200px;
    height: 200px;
  }
}

.hamburger-menu .hamburger-icon {
  position: absolute;
  top: 135px;
  left: 50px;
}

