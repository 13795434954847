/*process*/
.process {
	position: relative;
	z-index: 1;
	.process-number {
		position: absolute;
		left: 0;
		top: 0;
		font-size: 100px;
		font-weight: $font-weight-bolder;
		color: rgba(0, 0, 0, 0.1);
		z-index: -1;
	}
	.process-title,
	a {
		font-weight: $font-weight-bold;
	}
}

/*Process style 2 advance*/
.process-advance {
	.process-title {
		font-weight: $font-weight-bold;
	}
	.process-number {
		border-radius: 50%;
		height: 50px;
		width: 50px;
		line-height: 50px;
		color: $white;
		display: inline-block;
		font-size: 16px;
		font-weight: $font-weight-bold;
	}
	.process-border {
		position: relative;
		width: 100%;
		z-index: 1;
	}
	p {
		margin: 0;
	}
}

.process-advance {
	.border-end:after {
		content: "";
		position: absolute;
		top: 50%;
		display: block;
		width: calc(50% + 30px);
		border-top-width: 1px;
		border-top-style: solid;
		margin-top: -1px;
		right: -30px;
		border-color: $gray-300 !important;
		z-index: -1;
	}
	.border-start:before {
		content: "";
		position: absolute;
		top: 50%;
		display: block;
		width: calc(50% + 30px);
		border-top-width: 1px;
		border-top-style: solid;
		margin-top: -1px;
		left: -30px;
		border-color: $gray-300 !important;
		z-index: -1;
	}
}

@include media-breakpoint-down(md) {
	.process-advance .border-end:after,
	.process-advance .border-start:before {
		display: none;
	}
}

@include media-breakpoint-down(md) {
	.process {
		text-align: center;
	}
	.process .process-number {
		left: 0;
		right: 0;
	}
}
